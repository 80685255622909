h1 {
  font: normal 28px / 34px "Lato", Helvetica, Arial, Verdana, sans-serif;
  color: #3c3e45;
  font-weight: 500;
}

.text {
font: normal  15px / 26px "Montserrat", Helvetica, Arial, Verdana, sans-serif;
color: #72777d;
}

.btn-primary.btn  {
  color: white;
  border-color: #42a7d7;
  background-color: #42a7d7;
  font: normal 16px / 18px "Open Sans", Helvetica, Arial, Verdana, sans-serif;
  padding: 10px 16px 10px 16px;
  border-radius: 3px;
}

.btn-primary.btn:disabled  {
  color: white;
  border-color: #424242;
  background-color: #424242;
  font: normal 16px / 18px "Open Sans", Helvetica, Arial, Verdana, sans-serif;
  padding: 10px 16px 10px 16px;
  border-radius: 3px;
}

.invalid-tooltip-error {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 5;
    max-width: 100%;
    padding: .25rem .5rem;
    font-size: .875rem;
    line-height: 1.5;
    border-radius: .25rem;
    padding-top: 0%
}

.square {
  height: 20px;
  width: 20px;
}

.card-img.amb-cards {
  object-fit: contain;
}
@media screen and (min-width: 576px) {
  .card-img.amb-cards {
    max-height: 20rem;
  }
}
@media screen and (max-width: 575px) {
  .card-img.amb-cards {
    max-height: 15rem;
  }
}
@media screen and (max-width: 400px) {
  .card-img.amb-cards {
    max-height: 10rem;
  }
}
